<template>
  <v-theme-provider dark>
    <base-section
      id="info-alt"
    >
      <v-container>
        <v-row>
          <v-col
            cols="12"
            md="3"
          >
            <kdw-info />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <base-info-card :title="$t('kidiwiInfo.contact')" />
            <kdw-business-contact
              :business-prop="businessInfo"
              dense
            />
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <base-info-card :title="$t('kidiwiInfo.legal_title')" />
            <v-chip
              id="LegalMention"
              @click="openLegalMention"
            >
              {{ $t('kidiwiInfo.legal') }}
            </v-chip> <br><br>
            <v-chip
              id="CGV"
              @click="openCGV"
            >
              {{ $t('kidiwiInfo.cgu') }}
            </v-chip> <br><br>
            <v-chip
              id="GDPR"
              @click="openGDPR"
            >
              {{ $t('kidiwiInfo.gdpr') }}
            </v-chip> <br><br>
            <!--
            <v-chip
              @click="openCheckInOut"
            >
              Référencement
            </v-chip>
            -->
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <base-info-card title="FAQ" />
            <v-chip
              @click="openFAQ"
            >
              FAQ
            </v-chip> <br><br>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  import { loadFilePublic } from '@/helpers/GenericFunctionsS3Download'
  import { navigate } from '@/helpers/Navigation'

  // import { Logger, Storage } from 'aws-amplify'
  // const logger = new Logger('Info')

  export default {
    name: 'SectionBusinessInfo',

    components: {
      // InfoFeatures: () => import('@/components/InfoFeatures'),
      // Instagram: () => import('@/components/Instagram'),
    },

    data: () => ({
      // Example of format of the business Prop data:
      businessInfo: [
        {
          icon: 'mdi-map-marker-outline',
          title: 'Address',
          text: '1157 avenue de la Plaine, Les Jylloues<br>06250 Mougins - France',
        },
        {
          icon: 'mdi-cellphone',
          title: 'Phone',
          // text: '+33 6.03.15.94.66<br>+33 1.22.33.44.55',
          text: '+33 6.03.15.94.66',
        },
        {
          icon: 'mdi-email',
          title: 'Email',
          text: 'contact@kidiwidigital.com',
        },
      ],
    }),

    methods: {
      openLegalMention () {
        loadFilePublic('MentionLegales.pdf')
      },
      openCGV () {
        loadFilePublic('ConditionsGenerales.pdf')
      },

      openGDPR () {
        loadFilePublic('GDPR.pdf')
      },

      openFAQ () {
        navigate(this, 'AnonymousFaq')
      },
    },
  }
</script>

<style lang="sass">
  #info-alt a
    text-decoration: none
</style>
